import React from 'react'
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export default function Home() {
    var settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    var services = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
    };
    return (
        <div>
            <section className="banner">
                <div className="container">
                    <div className="wraper">
                        <div className="banner-ss">
                            <Slider {...settings}>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="content">
                                                <h1>Embassy of the <br />
                                                    Republic of Congo</h1>
                                                <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  <br />
                                                    has been the industry's standard dummy text ever since the 1500s, when an unknown <br />
                                                    printer took a galley of type and scrambled it to make a type specimen book.
                                                </p>
                                                <div className="butns">
                                                    <Link to="/about" className="p-btn"><span> Read More</span><img src="./images/baner-btn.png" alt="Go Button" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            {/* <div class="embasshy-img">
                                          <img src="./images/man.png " alt="Embassy Man" />
                                      </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="content">
                                                <h1>Embassy of the <br />
                                                    Republic of Congo</h1>
                                                <p>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum  <br />
                                                    has been the industry's standard dummy text ever since the 1500s, when an unknown <br />
                                                    printer took a galley of type and scrambled it to make a type specimen book.
                                                </p>
                                                <div className="butns">
                                                    <Link to="/about" className="p-btn"><span> Read More</span><img src="./images/baner-btn.png" alt="Go Button" /></Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                            {/* <div class="embasshy-img">
                                              <img src="./images/man.png " alt="Embassy Man" />
                                          </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ss-buts">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 colLeft">
                            <div className="content">
                                <h4><span>Strengthening Bilateral <br />
                                    Relations and Serving Our Citizens</span>
                                </h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-12 slidbuts"> </div>
                        <div className="col-lg-6 col-12 colRight">
                            <ul className="social">
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-linkedin" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-twitter" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-instagram" aria-hidden="true" /></Link>
                                </li>
                                <li>
                                    <Link to="#" tabIndex={0}><i className="fa fa-facebook" aria-hidden="true" /></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/about-img.png" alt="About Us" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 colright">
                            <div className="ab-text wow fadeInLeft">
                                <h2>About Us</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                                <ul>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                    <li><img src="./images/checked.png" alt="Checked" /><h6>exercitation ullamco laboris</h6></li>
                                </ul>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.  <br />
                                    It has survived not only five centuries.
                                </p>
                                <div className="butns">
                                    <Link to="/about" className="p-btn"><span> Read More</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services">
                <div className="container-fluid">
                    <div className="heatest text-center">
                        <h2>Consular Services</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="home-services">
                        <Slider {...services}>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>Consular Services</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>Consec Adipisicing Elit</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>Consular Services</h6>
                                        <img src="./images/ss2.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>Consec Adipisicing Elit</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>Consular Services</h6>
                                        <img src="./images/ss3.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>Consec Adipisicing Elit</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="box">
                                    <div className="ss-img">
                                        <h6>Consular Services</h6>
                                        <img src="./images/ss1.png" alt="Services" />
                                    </div>
                                    <div className="ss-content">
                                        <h3>Consec Adipisicing Elit</h3>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisic-<br />ing elit, sed do eiusmod tempor incididunt ut <br />   labore et dolore magna aliqua. Ut enim
                                        </p>
                                        <div className="butns">
                                            <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn" tabIndex={0}><span>View Services</span><img src="./images/red-btn.png" alt="Go Btn" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>
            <section className="visa">
                <div className="container-fluid">
                    <div className="wraper">
                        <div className="row">
                            <div className="col-lg-4 col-lg-4 col-12">
                                <div className="visa-cd">
                                    <h2>Visa Information</h2>
                                    <p>Lorem Ipsum is simply dummy text of the printing and <br />
                                        typesetting industry. Lorem Ipsum has been the <br />
                                        industry's standard dummy text.
                                    </p>
                                    <p>Lorem Ipsum is simply dummy text of the printing and <br />
                                        typesetting industry. Lorem Ipsum has been the <br />
                                        industry's standard dummy text.
                                    </p>
                                    <div className="butns">
                                        <Link to="https://demo-customlinks.com/embassy_dev/" className="p-btn"><span> Apply Now</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12" />
                            <div className="col-lg-7 col-md-7 col-12">
                                <div className="guide">
                                    <h4>Your Guide to Applying for a Visa</h4>
                                </div>
                            </div>
                            <div className="col-lg-1 col-md-1 col-12" />
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12" />
                            <div className="col-lg-7 col-md-7 col-12" />
                            <div className="col-lg-1 col-md-1 col-12 colsmend">
                                <span />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="tourism">
                <div className="container">
                    <div className="heatest text-center">
                        <h6>Tourism &amp; Investment</h6>
                        <h2>Explore Opportunities <br />
                            in the Republic of Congo
                        </h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />
                            the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="parent">
                        <div className="row">
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand1.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Consec Adipisicing</h3>
                                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu.</p>
                                        <p className="para">xcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand2.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Consec Adipisicing</h3>
                                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu.</p>
                                        <p className="para">xcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand3.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Consec Adipisicing</h3>
                                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu.</p>
                                        <p className="para">xcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-lg-3 col-12">
                                <div className="box">
                                    <div className="box-img">
                                        <img src="./images/hand4.png" alt="Investment Icons" />
                                    </div>
                                    <div className="content">
                                        <h3>Consec Adipisicing</h3>
                                        <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu.</p>
                                        <p className="para">xcepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim</p>
                                        <div className="linsk">
                                            <Link to="/guidance-advisory"><i className="fa fa-angle-right" aria-hidden="true" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="economic">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12 colLeft">
                            <div className="ab-text fadeInLeft">
                                <h2>Economic &amp;
                                    <br />Political Relations</h2>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem <br />
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an <br />
                                    unknown printer took a galley of type and scrambled it to make a type specimen book. <br />
                                    It has survived not only five centuries.
                                </p>
                                <p>But also the leap into electronic typesetting, remaining essentially unchanged. It was <br />
                                    popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum <br />
                                    passages, and more recently with desktop publishing software like Aldus <br />
                                </p>
                                <div className="butns">
                                    <Link to="/guidance-advisory" className="p-btn"><span> Learn More</span><img src="./images/red-btn.png" alt="Go Button" /></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12  colright">
                            <div className="ab-img wow fadeInRight">
                                <img src="./images/chose-img.png" alt="economic" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="news">
                <div className="container">
                    <div className="heatest text-center">
                        <h2>Latest News &amp; Events</h2>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been <br />
                            the industry's standard dummy text.
                        </p>
                    </div>
                    <div className="row ">
                        <div className="col-lg-6 col-md-6  col-12 coleftSm">
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/events">
                                                <img src="./images/psm1.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/events">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="postList">
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-12">
                                        <div className="post-img">
                                            <Link to="/events">
                                                <img src="./images/psm2.png" alt="News" />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-7 col-12">
                                        <div className="post-content">
                                            <Link to="/events">
                                                <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                                <div className="user-box">
                                                    <div className="user-img">
                                                        <img src="./images/admin1.png" alt="Admin" />
                                                    </div>
                                                    <div className="user-name">
                                                        <h6>lora stevenson</h6>
                                                        <span><strong>June 12</strong></span>
                                                    </div>
                                                </div>
                                            </Link>
                                            <Link to="/events" className="copy"><img src="./images/copy.png" alt="Copy" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 viewpost">
                            <div className="row">
                                <Link to="/events">
                                    <div className="bg-img">
                                        <img src="./images/plg1.png" alt="View Post" />
                                    </div>
                                </Link>
                                <div className="post-view">
                                    <Link to="/events">
                                        <h5>dolor sit amet consecteutr <br /> elit sed do quis</h5>
                                        <div className="user-box">
                                            <div className="user-img">
                                                <img src="./images/admin2.png" alt="Admin" />
                                            </div>
                                            <div className="user-name">
                                                <h6>lora stevenson</h6>
                                                <span><strong>June 12</strong></span>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to="/events" className="copy"><img src="./images/white-shap.png" alt="Copy" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
